<template>
    <div>
        <img :src="`data:image/png;base64,${result}`" />
    </div>
</template>
<script>
import { BASE_URL } from "../../common";
import axios from "axios";
export default {
    data() {
        return {
            result: "",
        };
    },
    mounted() {
        let url = this.$route.query.data;
        console.log("data is", url);
        this.viewDocument(url);
    },
    methods: {
        viewDocument(docUrl) {
            if (localStorage.cs_user_token) {
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                });
                axios
                    .get(
                        `${BASE_URL}${docUrl}/?token=${localStorage.cs_user_token}`
                    )
                    .then((res) => {
                        loader.hide();
                        this.result = res.data.file;
                    });
            }
        },
    },
};
</script>
